export interface AlertPreferences {
	accountId?: string;
	accountAlertPreferences: AccountAlertPreferences[];
	ccEntities?: CCEntities;
}

export interface CCEntities {
	userIds?: Array<string>;
	distributionGroupIds?: Array<string>;
}

export interface AccountAlertPreferences {
	threat: string;
	threatAlertPreference?: ThreatAlertPreference[];
}

interface ThreatAlertPreference {
	userType?: string;
	isEnabled: boolean;
}

export enum Threats {
	UnusualLocation = 'UnusualLocation',
	UnusualLocationAndDevice = 'UnusualLocationAndDevice',
	UnusualAuthFailure = 'UnusualAuthFailure',
	MalwareUpload = 'MalwareUpload',
}

export enum UserRole {
	MasterAdmin = 'MasterAdmin',
	Employee = 'Employee',
	Client = 'Client',
}

export interface UserAlertPreferences {
	userId?: string;
	userAlertPreferences: UserAlertPreference[];
}

export interface UserAlertPreference {
	threat: Threats;
	isEnabled: boolean;
	isEnabledByAdmin: boolean;
}

export interface ThreatUserPreference {
	isEnabled: boolean;
	isEnabledByAdmin: boolean;
}
