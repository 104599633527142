import {
	AccountAlertPreferences,
	AlertPreferences,
	CCEntities,
	UserAlertPreference,
	UserAlertPreferences,
	UserRole,
} from '../../SecurityAlertSettings/types';

interface Configuration {
	origin: string;
	apiPath: string;
}

const configuration: Configuration = {
	origin: window.origin,
	apiPath: '/io/threatalertmanagement/',
};

function createRequest(path: string, init: RequestInit = {}) {
	const url = `${configuration.origin}${configuration.apiPath}${path}`;
	init.headers = { ...init.headers, 'X-BFF-CSRF': 'true' };
	return new Request(url, init);
}

export const ThreatAlertManagementHttp = {
	async getAlertPreferences() {
		const request = createRequest('api/AlertManager', {
			method: 'GET',
		});
		const response = await fetchWithError(
			request,
			'Unable to get threat alert preferences'
		);
		const responseText = await response.text();
		const responseJson = JSON.parse(responseText) as AlertPreferences;
		return responseJson;
	},
	async saveAlertPreferences(AccountAlertPreferences: AccountAlertPreferences[]) {
		const request = createRequest('api/AlertManager', {
			body: JSON.stringify({ AccountAlertPreferences }),
			method: 'PATCH',
			headers: {
				'content-type': 'application/json',
			},
		});
		const response = await fetchWithError(
			request,
			'Unable to save threat alert preferences'
		);
		if (response.status === 200) {
			return true;
		}
		return false;
	},
	async getUserAlertPreferences(userType: UserRole) {
		const request = createRequest(`api/UserAlertManager?userType=${userType}`, {
			method: 'GET',
		});
		const response = await fetchWithError(
			request,
			'Unable to get user threat alert preferences'
		);
		const responseText = await response.text();
		const responseJson = JSON.parse(responseText) as UserAlertPreferences;

		return responseJson?.userAlertPreferences;
	},
	async saveUserAlertPreferences(UserAlertPreferences: UserAlertPreference[]) {
		const request = createRequest('api/UserAlertManager', {
			body: JSON.stringify({ UserAlertPreferences }),
			method: 'PATCH',
			headers: {
				'content-type': 'application/json',
			},
		});
		const response = await fetchWithError(
			request,
			'Unable to save user threat alert preferences'
		);

		if (response.status === 200) {
			return true;
		}

		return false;
	},
	async saveCCEntities(cCEntities: CCEntities) {
		const request = createRequest('api/CCEntities', {
			body: JSON.stringify({ cCEntities }),
			method: 'PATCH',
			headers: {
				'content-type': 'application/json',
			},
		});
		const response = await fetchWithError(request, 'Unable to update CC entities');

		if (response.status === 200) {
			return true;
		}

		return false;
	},
};

async function fetchWithError(request: Request, errorMessage: string) {
	const response = await fetch(request);
	if (response.status > 399) {
		const text = await response.text();
		throw text ? JSON.parse(text) : errorMessage;
	}
	return response;
}
