import * as React from 'react';
import { Api } from '@citrite/sf-api';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import { ShareFileUserContextProvider } from './contexts/ShareFileUserContext';
import { SecuritySettingsWrapper } from './SecuritySettingsWrapper';
import { UserSecurityAlertSettings } from './UserSecurityAlertSettings/UserSecurityAlertSettings';
import { LegacyTokenInterceptor } from './utils/LegacyTokenInterceptor/LegacyTokenInterceptor';

export function setup(app: PiletApi) {
	app.sf.registerSfApiConfigHandler(Api.configure);

	app.registerExtension('urn:sfextslot:sharefile-appshell:threatalert-mgt-pilet', () => (
		<LegacyTokenInterceptor>
			<SecuritySettingsWrapper />
		</LegacyTokenInterceptor>
	));

	app.registerExtension(
		'urn:sfextslot:sharefile-appshell:threatalert-mgt-pilet:user-preferences',
		() => (
			<LegacyTokenInterceptor>
				<ShareFileUserContextProvider {...app.sf.piletUserContext.get()}>
					<UserSecurityAlertSettings />
				</ShareFileUserContextProvider>
			</LegacyTokenInterceptor>
		)
	);
}
