import React, { useEffect, useState } from 'react';
import {
	Button,
	LoadingPrimary,
	notifyError,
	notifySuccess,
	SettingsField,
} from '@citrite/citrix-ui';
import { UserRole } from '@citrite/sf-api';
import { useShareFileUserContext } from '../contexts/ShareFileUserContext';
import { Description, StyledRow, TableContent } from '../SecurityAlertSettings/styles';
import {
	Threats,
	ThreatUserPreference,
	UserAlertPreference,
} from '../SecurityAlertSettings/types';
import * as SecurityAlertSettingsTypes from '../SecurityAlertSettings/types';
import { t } from '../utils';
import { ThreatAlertManagementHttp } from '../utils/ThreatAlertManagementService/ThreatAlertManagementHttp';
import { AlertTypeColumn, NotifyColumn, RowSeparator, SectionHeader } from './styles';
import { ToggleAlert } from './ToggleAlert';

export const UserSecurityAlertSettings = () => {
	const { sfUser } = useShareFileUserContext();
	const [loading, setLoading] = useState<boolean>(false);
	const [userPreferences, setUserPreferences] = useState<Array<UserAlertPreference>>(
		Array<UserAlertPreference>()
	);
	const [saving, setSaving] = useState<boolean>(false);

	const getThreatMessage = (threat: Threats) => {
		switch (threat) {
			case Threats.UnusualLocation:
				return t('alert-management:user_settings_dashboard.messages.unusual_location');
			case Threats.UnusualLocationAndDevice:
				return t(
					'alert-management:user_settings_dashboard.messages.unusual_location_and_device'
				);
			case Threats.UnusualAuthFailure:
				return t(
					'alert-management:user_settings_dashboard.messages.unusual_auth_failure'
				);
			case Threats.MalwareUpload:
				return t('alert-management:user_settings_dashboard.messages.malware_upload');
			default:
				return '';
		}
	};

	const onPreferenceChange = (threatType: Threats, status: boolean) => {
		const preference = userPreferences.find(
			up => up.threat == threatType
		) as ThreatUserPreference;

		if (preference) {
			preference.isEnabled = status;
		}

		setUserPreferences(userPreferences);
	};

	const save = () => {
		setSaving(true);

		ThreatAlertManagementHttp.saveUserAlertPreferences(userPreferences)
			.then(() => {
				notifySuccess(t('alert-management:dashboard.save_success'));
			})
			.catch(() => {
				notifyError(t('alert-management:dashboard.error_message'));
			})
			.finally(() => {
				setSaving(false);
			});
	};

	const threatAlertPreferenceSections = (
		types: Array<Threats>,
		sectionHeader: string
	) => {
		return (
			<>
				<tr>
					<SectionHeader>{sectionHeader}</SectionHeader>
					<SectionHeader></SectionHeader>
				</tr>
				{types.map((type, index) => threatAlertPreference(type, index))}
			</>
		);
	};

	const threatAlertPreference = (type: Threats, index: number) => {
		return (
			<ToggleAlert
				key={index}
				message={getThreatMessage(type)}
				threatType={type}
				isEnabled={userPreferences.find(up => up.threat == type)?.isEnabled ?? true}
				isEnabledByAdmin={
					userPreferences.find(up => up.threat == type)?.isEnabledByAdmin ?? false
				}
				onChange={onPreferenceChange}
			/>
		);
	};

	const getUserType = () => {
		if (sfUser.Roles?.includes(UserRole.MasterAdmin)) {
			return SecurityAlertSettingsTypes.UserRole.MasterAdmin;
		}

		if (sfUser.Roles?.includes(UserRole.Employee)) {
			return SecurityAlertSettingsTypes.UserRole.Employee;
		}

		return SecurityAlertSettingsTypes.UserRole.Client;
	};

	useEffect(
		() => {
			setLoading(true);
			ThreatAlertManagementHttp.getUserAlertPreferences(getUserType())
				.then(value => {
					if (typeof value === undefined || value == null) {
						return;
					}

					setUserPreferences(value);
				})
				.catch(() => {
					notifyError(t('alert-management:dashboard.error_message'));
				})
				.finally(() => {
					setLoading(false);
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const view = () => {
		if (loading) {
			return <LoadingPrimary />;
		}

		return (
			<>
				<SettingsField label={t('alert-management:user_settings_dashboard.title')}>
					<Description>
						{t('alert-management:user_settings_dashboard.description')}
					</Description>
					<StyledRow />
					<TableContent>
						<thead>
							<tr>
								<AlertTypeColumn>
									{t('alert-management:user_settings_dashboard.alert_header')}
								</AlertTypeColumn>
								<NotifyColumn>
									{t('alert-management:user_settings_dashboard.notify_header')}
								</NotifyColumn>
							</tr>
						</thead>
						<tbody>
							{threatAlertPreferenceSections(
								[Threats.UnusualLocation, Threats.UnusualLocationAndDevice],
								t('alert-management:user_settings_dashboard.unusual_signin')
							)}
							<tr>
								<RowSeparator>
									<StyledRow />
								</RowSeparator>
								<RowSeparator>
									<StyledRow />
								</RowSeparator>
							</tr>
							{threatAlertPreferenceSections(
								[Threats.UnusualAuthFailure],
								t('alert-management:user_settings_dashboard.failed_attempts')
							)}
							{threatAlertPreferenceSections(
								[Threats.MalwareUpload],
								t('alert-management:user_settings_dashboard.malware_upload')
							)}
						</tbody>
					</TableContent>
					<Button.Secondary
						data-pendo-id="SaveUserThreatAlertPilet"
						onClick={save}
						active={saving}
					>
						{t('alert-management:dashboard.save')}
					</Button.Secondary>
				</SettingsField>
			</>
		);
	};

	return view();
};
