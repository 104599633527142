import styled from '@emotion/styled';

export const StyledInfo = styled.div`
	margin-top: 24px;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 8px;
`;

export const StyledContactInfo = styled.div`
	margin: 12px 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const StyledSaveButtonDiv = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 12px;
`;

export const StyledErrorMessage = styled.div`
	color: red;
	font-size: 12px;
`;
