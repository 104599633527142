import * as React from 'react';
import { Checkbox } from '@citrite/citrix-ui';
import { Message, PermissionsColumn } from './styles';
import { UserRole } from './types';

interface Props {
	alertMessage: string;
	isAdminAlertChecked: boolean;
	isEmployeeAlertChecked: boolean;
	isClientAlertChecked: boolean;
	threatName: any;
	onChange: any;
}

interface State {
	isAdminAlertChecked: boolean;
	isEmployeeAlertChecked: boolean;
	isClientAlertChecked: boolean;
}

export class ThreatAlert extends React.Component<Props, State> {
	public state: State = {
		isAdminAlertChecked: this.props.isAdminAlertChecked,
		isEmployeeAlertChecked: this.props.isEmployeeAlertChecked,
		isClientAlertChecked: this.props.isClientAlertChecked,
	};

	public render() {
		return (
			<tbody>
				<tr>
					<Message>{this.props.alertMessage}</Message>
					<PermissionsColumn>
						<Checkbox
							id="admin-checkbox"
							checked={this.state.isAdminAlertChecked}
							onChange={() => {
								this.setState(
									state => ({
										isAdminAlertChecked: !state.isAdminAlertChecked,
									}),
									() =>
										this.props.onChange(
											this.props.threatName,
											UserRole.MasterAdmin,
											this.state.isAdminAlertChecked
										)
								);
							}}
						/>
					</PermissionsColumn>
					<PermissionsColumn>
						<Checkbox
							id="employee-checkbox"
							checked={this.state.isEmployeeAlertChecked}
							onChange={() => {
								this.setState(
									state => ({
										isEmployeeAlertChecked: !state.isEmployeeAlertChecked,
									}),
									() =>
										this.props.onChange(
											this.props.threatName,
											UserRole.Employee,
											this.state.isEmployeeAlertChecked
										)
								);
							}}
						/>
					</PermissionsColumn>
					<PermissionsColumn>
						<Checkbox
							id="client-checkbox"
							checked={this.state.isClientAlertChecked}
							onChange={() => {
								this.setState(
									state => ({
										isClientAlertChecked: !state.isClientAlertChecked,
									}),
									() =>
										this.props.onChange(
											this.props.threatName,
											UserRole.Client,
											this.state.isClientAlertChecked
										)
								);
							}}
						/>
					</PermissionsColumn>
				</tr>
			</tbody>
		);
	}
}
