import React from 'react';
import { Threats } from '../SecurityAlertSettings/types';
import { AlertDescription, StyledToggle } from './styles';

export interface ToggleAlertProps {
	message: string;
	threatType: Threats;
	isEnabled: boolean;
	isEnabledByAdmin: boolean;
	onChange: (threatType: Threats, status: boolean) => void;
}

export const ToggleAlert = (props: ToggleAlertProps) => {
	const [alertStatus, setAlertStatus] = React.useState<boolean>(
		props.isEnabledByAdmin ? true : props.isEnabled
	);

	return (
		<tr>
			<AlertDescription>{props.message}</AlertDescription>
			<td>
				<StyledToggle
					on={alertStatus}
					disabled={props.isEnabledByAdmin}
					data-testid={`toggle-${props.threatType}`}
					data-pendo-id={`toggle-${props.threatType}`}
					onToggle={() => {
						props.onChange(props.threatType, !alertStatus);
						setAlertStatus(!alertStatus);
					}}
				/>
			</td>
		</tr>
	);
};
