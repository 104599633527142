import React from 'react';
import styled from '@emotion/styled';
import { layout } from '@sharefiledev/flow-web';

type LayoutSpacingKeys = keyof typeof layout;
type SpacingOptions = (typeof layout)[LayoutSpacingKeys];

interface Props {
	children?: React.ReactNode | React.ReactNodeArray;
	inline?: boolean;
	gap?: SpacingOptions | string;
	padding?: SpacingOptions;
	margin?: SpacingOptions;
	column?: boolean;
	/** Alignment of children */
	align?: 'center' | 'start' | 'end' | 'baseline';
	/** Spacing of children */
	spacing?: 'center' | 'start' | 'end' | 'space-between' | 'space-around';
	hFill?: boolean;
	vFill?: boolean;
	/** Disable flex-grow and flex-shrink as child node of flex component */
	disableResizing?: boolean;
	wrap?: 'wrap' | 'wrap-reverse';
	grow?: number;
	shrink?: number;
	basis?: string;
}

export const Flex = styled.div<Props>`
	box-sizing: border-box;
	display: ${props => (props.inline ? 'inline-flex' : 'flex')};
	gap: ${props => props.gap ?? undefined};
	padding: ${props => props.padding ?? undefined};
	margin: ${props => props.margin ?? undefined};
	flex-direction: ${props => (props.column ? 'column' : undefined)};
	align-items: ${props => props.align};
	justify-content: ${props => props.spacing};
	width: ${props => (props.hFill ? '100%' : undefined)};
	height: ${props => (props.vFill ? '100%' : undefined)};
	flex: ${props => (props.disableResizing ? 0 : undefined)};
	flex-wrap: ${props => props.wrap ?? undefined};
	flex-grow: ${props => props.grow ?? undefined};
	flex-shrink: ${props => props.shrink ?? undefined};
	flex-basis: ${props => props.basis ?? undefined};
`;
