import { Toggle } from '@citrite/citrix-ui';
import styled from '@emotion/styled';

export const AlertTypeColumn = styled.th`
	font-size: 11px;
	font-weight: 500;
	width: 90%;
	text-align: left;
`;

export const NotifyColumn = styled.th`
	font-size: 11px;
	font-weight: 500;
	width: 10%;
	text-align: left;
`;

export const RowSeparator = styled.td`
	padding: 0;
`;

export const SectionHeader = styled.td`
	font-size: 16px;
	font-weight: 600;
	line-height: 18px;
	padding-top: 16px;
	padding-bottom: 8px;
`;

export const AlertDescription = styled.td`
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	padding: 12px 0;
`;

export const StyledToggle = styled(Toggle)`
	padding: 16px 0;
`;
