import React, { useEffect, useState } from 'react';
import {
	AlertErrorIcon,
	EmptyState,
	LoadingPrimary,
	notifyError,
} from '@citrite/citrix-ui';
import { AlternateContactSettings } from './AlternateContactSettings/AlternateContactSettings';
import { SecurityAlertSettings } from './SecurityAlertSettings';
import { AccountAlertPreferences, CCEntities } from './SecurityAlertSettings/types';
import { t } from './utils';
import { ThreatAlertManagementHttp } from './utils/ThreatAlertManagementService/ThreatAlertManagementHttp';

export const SecuritySettingsWrapper = () => {
	const emptyCCEntities: CCEntities = { userIds: [], distributionGroupIds: [] };
	const [loading, setLoading] = useState<boolean>(true);
	const [threatAlertPreferences, setThreatAlertPreferences] = useState<
		Array<AccountAlertPreferences>
	>([]);
	const [ccEntities, setCCEntities] = useState<CCEntities>(emptyCCEntities);
	const [fetchSuccess, setFetchSuccess] = useState<boolean>(false);

	useEffect(
		() => {
			setLoading(true);
			ThreatAlertManagementHttp.getAlertPreferences()
				.then(value => {
					setFetchSuccess(true);
					setThreatAlertPreferences(value.accountAlertPreferences);
					setCCEntities(value.ccEntities || emptyCCEntities);
				})
				.catch(() => {
					notifyError(t('alert-management:dashboard.error_message'));
					setFetchSuccess(false);
				})
				.finally(() => {
					setLoading(false);
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const getView = () => {
		if (loading) {
			return <LoadingPrimary />;
		}

		if (fetchSuccess === false) {
			return (
				<EmptyState
					icon={AlertErrorIcon}
					title={t('alert-management:dashboard.error_message')}
					data-testid="error-message"
				/>
			);
		}

		return (
			<>
				<SecurityAlertSettings threatAlertPreferences={threatAlertPreferences} />
				<AlternateContactSettings CCEntities={ccEntities} />
			</>
		);
	};

	return getView();
};
