import { fromThemeColors, ThemeColor } from '@citrite/citrix-ui';
import styled from '@emotion/styled';

export const StyledRow = styled.hr`
	border-top: 1px solid;
	border-color: ${fromThemeColors(ThemeColor.border)};
	margin: 15px 0px;
`;

export const BoldText = styled.div`
	font-weight: 600;
`;
export const BoldTextHeading = styled.div`
	width: 354px;
	font-weight: 600;
	text-align: center;
	padding-bottom: 7px;
	align-items: center;
`;

export const Description = styled.div`
	font-size: 14px;
	font-weight: 500;
`;

export const PermissionsColumn = styled.th`
	width: 90px;
	text-align: center;
	padding: 0 14px;
	font-weight: normal;
	font-size: 11px;
`;

export const TableContent = styled.table`
	width: 100%;
	margin: 0 auto;
	margin-bottom: 20px;
	border-collapse: collapse;
`;
export const TableHeader = styled.table`
	width: 100%;
	margin: 0 auto;
	border-collapse: collapse;
`;

export const Message = styled.td`
	text-align: left;
	padding: 16px 0;
`;
export const MessageHeading = styled.td`
	justify-content: flex-start;
	font-weight: 600;
`;

export const AlignRight = styled.div`
	display: flex;
	justify-content: flex-end;
`;
