/* tslint:disable */
/* This file was generated by @citrite/translate. Manual changes will be lost. */
import { Language } from '@citrite/translate';
export type locale =
	| 'de'
	| 'en'
	| 'es'
	| 'fr'
	| 'it'
	| 'ja'
	| 'ko'
	| 'nl'
	| 'pt-BR'
	| 'ru'
	| 'zh-CN';
export const languages: Language[] = [
	{
		key: 'de',
		content: {
			dashboard: {
				add_alternate_contacts: 'Alternative Kontakte hinzufügen',
				admin: 'Admin',
				alert_disclaimer:
					'Sicherheitsbenachrichtigungen werden gesendet, wenn potenziell bösartige Aktivitäten erkannt werden. Wählen Sie aus, über welche Aktivitäten Ihre Benutzer benachrichtigt werden sollen.',
				alternate_contact_description:
					'Sie können bis zu zwei Benutzer oder zwei Verteilergruppen konfigurieren, um die Benachrichtigungen zu erhalten',
				alternate_contact_error_count:
					'Sie können insgesamt nur vier alternative Kontakte hinzufügen',
				alternate_contact_error_dg:
					'Sie können insgesamt nur zwei Verteilergruppen als alternative Kontakte hinzufügen',
				alternate_contact_error_user:
					'Sie können insgesamt nur zwei Benutzer als alternative Kontakte hinzufügen',
				alternate_contact_field: 'Benutzer/Verteilergruppen',
				alternate_contact_header: 'Alternative Kontakte',
				clients: 'Clients',
				employees: 'Mitarbeiter',
				error_message:
					'Benachrichtigungseinstellungen konnten nicht abgerufen werden. Bitte versuchen Sie es später noch einmal.',
				messages: {
					malware_upload: 'Eine verdächtige Datei wird in einen Ordner hochgeladen',
					unusual_auth_failure:
						'Es gibt mehrere fehlgeschlagene Anmeldeversuche für das Konto eines Benutzers',
					unusual_location: 'Ein Benutzer meldet sich aus einem anderen Land an',
					unusual_location_and_device:
						'Ein Benutzer meldet sich aus einer anderen Stadt mit einem anderen Gerät an',
				},
				save: 'Speichern',
				save_success: 'Benachrichtigungseinstellungen erfolgreich gespeichert',
				save_success_alternate_contact: 'Alternative Kontakte wurden gespeichert',
				save_unsuccessful:
					'Die Einstellungen für Benachrichtigungen über Bedrohungen können nicht gespeichert werden',
				save_unsuccessful_alternate_contact:
					'Alternative Kontakte konnten nicht gespeichert werden',
				send_alert: 'Benachrichtigungen immer senden, wenn...',
				send_alert_to: 'Benachrichtigungen über Aktivitäten senden an...',
				title: 'Einstellungen für Sicherheitsbenachrichtigungen',
			},
			legacyTokenInterceptor: {
				buttonText: 'Anmelden',
				message:
					'Sie werden direkt hierher zurückgebracht, damit Sie dort weitermachen können, wo Sie aufgehört haben.',
				title:
					'Erneut anmelden, um auf die Einstellungen für Sicherheitsbenachrichtigungen zuzugreifen',
			},
			user_settings_dashboard: {
				alert_header: 'Benachrichtigungstyp',
				description:
					'Benachrichtigungen über Sicherheitsbedrohungen werden Ihnen per E-Mail zugeschickt, wenn potenziell böswillige Aktivitäten entdeckt werden. Verwenden Sie die nachstehenden Einstellungen, um festzulegen, für welche Aktivität Sie E-Mail-Benachrichtigungen erhalten möchten.',
				failed_attempts: 'Mehrere fehlgeschlagene Anmeldeversuche',
				malware_upload: 'Malware-Upload',
				messages: {
					malware_upload:
						'Wird ausgelöst, wenn eine verdächtige Datei in einen Ordner hochgeladen wird',
					unusual_auth_failure:
						'Wird ausgelöst, wenn Anmeldeversuche für das Konto eines Benutzers mehrfach fehlschlagen',
					unusual_location:
						'Jedes Mal, wenn sich ein Benutzer aus einem anderen Land anmeldet',
					unusual_location_and_device:
						'Jedes Mal, wenn sich ein Benutzer aus einem anderen Land mit einem anderen Gerät anmeldet',
				},
				notify_header: 'Benachrichtigen',
				title: 'Sicherheitsbedrohungsbenachrichtigungen',
				unusual_signin: 'Ungewöhnliche Anmeldung',
			},
		},
	},
	{
		key: 'en',
		content: {
			dashboard: {
				add_alternate_contacts: 'Add Alternate Contacts',
				admin: 'Admin',
				alert_disclaimer:
					'We send security alerts whenever we detect potentially malicious activity. Choose which activities you’d like us to notify your users about.',
				alternate_contact_description:
					'You can configure up to two users or two distribution groups to recieve the alerts',
				alternate_contact_error_count:
					'you can only add a total of four alternate contacts',
				alternate_contact_error_dg:
					'you can only add a total of two Distirbution Groups as alternate contacts',
				alternate_contact_error_user:
					'you can only add a total of two users as alternate contacts',
				alternate_contact_field: 'User / Distirbution Groups',
				alternate_contact_header: 'Alternate Contacts',
				clients: 'Clients',
				employees: 'Employees',
				error_message: 'Failed to fetch alert preferences. Please try again later.',
				messages: {
					malware_upload: 'A suspicious file is uploaded to a folder',
					unusual_auth_failure:
						'There are multiple failed sign-in attempts on a user’s account',
					unusual_location: 'A user signs in from a different country',
					unusual_location_and_device:
						'A user signs in from a different city using a different device',
				},
				save: 'Save',
				save_success: 'Alert preferences saved successfully',
				save_success_alternate_contact: 'Alternate contacts saved successfully',
				save_unsuccessful: 'Unable to save threat alert preferences',
				save_unsuccessful_alternate_contact: 'Unable to save alternate contacts',
				send_alert: 'Send an alert whenever...',
				send_alert_to: 'Send notifications about activity to...',
				title: 'Security alert settings',
			},
			legacyTokenInterceptor: {
				buttonText: 'Sign in',
				message: "We'll bring you right back here so you can pick up where you left off.",
				title: 'Sign in again to access the Security Alert Notification Settings',
			},
			user_settings_dashboard: {
				alert_header: 'Alert type',
				description:
					'Notifications for security threats are sent to you over email every time potentially malicious activity is detected. Use the below settings to select what activity you would like to receive email notifications for.',
				failed_attempts: 'Multiple failed sign-in attempts',
				malware_upload: 'Malware upload',
				messages: {
					malware_upload: 'Triggered when a suspicious file is uploaded to a folder',
					unusual_auth_failure:
						"Triggered when there are multiple failed sign-in attempts on a user's account",
					unusual_location: 'Every time user signs in from a different country',
					unusual_location_and_device:
						'Every time user signs in from a different country using a different device',
				},
				notify_header: 'Notify me',
				title: 'Security Threat Notifications',
				unusual_signin: 'Unusual sign-in',
			},
		},
	},
	{
		key: 'es',
		content: {
			dashboard: {
				add_alternate_contacts: 'Agregar contactos alternativos',
				admin: 'Administración',
				alert_disclaimer:
					'Enviamos alertas de seguridad siempre que detectamos una actividad potencialmente maliciosa. Elija sobre qué actividades quiere que notifiquemos a los usuarios.',
				alternate_contact_description:
					'Puede configurar hasta dos usuarios o dos grupos de distribución para recibir las alertas',
				alternate_contact_error_count:
					'solo puede agregar un total de cuatro contactos alternativos',
				alternate_contact_error_dg:
					'solo puede agregar un total de dos grupos de distribución como contactos alternativos',
				alternate_contact_error_user:
					'solo puede agregar un total de dos usuarios como contactos alternativos',
				alternate_contact_field: 'Usuario/grupos de distribución',
				alternate_contact_header: 'Contactos alternativos',
				clients: 'Clientes',
				employees: 'Empleados',
				error_message:
					'Error al obtener las preferencias de alertas. Inténtelo de nuevo más tarde.',
				messages: {
					malware_upload: 'Se carga un archivo sospechoso en una carpeta',
					unusual_auth_failure:
						'Hay varios intentos fallidos de inicio de sesión en la cuenta de un usuario',
					unusual_location: 'Un usuario inicia sesión desde un país diferente',
					unusual_location_and_device:
						'Un usuario inicia sesión desde otra ciudad con un dispositivo diferente',
				},
				save: 'Guardar',
				save_success: 'Preferencias de alertas guardadas correctamente',
				save_success_alternate_contact: 'Contactos alternativos guardados correctamente',
				save_unsuccessful: 'No se pueden guardar las preferencias de alertas de amenazas',
				save_unsuccessful_alternate_contact:
					'No se pueden guardar los contactos alternativos',
				send_alert: 'Enviar una alerta cuando...',
				send_alert_to: 'Enviar notificaciones sobre la actividad a...',
				title: 'Parámetros de alertas de seguridad',
			},
			legacyTokenInterceptor: {
				buttonText: 'Iniciar sesión',
				message: 'Le traeremos de regreso aquí para que pueda continuar donde lo dejó.',
				title:
					'Inicie sesión de nuevo para acceder a los parámetros de notificación de alertas de seguridad',
			},
			user_settings_dashboard: {
				alert_header: 'Tipo de alerta',
				description:
					'Las notificaciones sobre amenazas de seguridad se le envían por correo electrónico cada vez que se detecta una actividad potencialmente maliciosa. Use los parámetros siguientes para seleccionar sobre qué actividades quiere recibir notificaciones por correo electrónico.',
				failed_attempts: 'Varios intentos fallidos de inicio de sesión',
				malware_upload: 'Carga de malware',
				messages: {
					malware_upload:
						'Se desencadena cuando se carga un archivo sospechoso en una carpeta',
					unusual_auth_failure:
						'Se desencadena cuando hay varios intentos fallidos de inicio de sesión en la cuenta de un usuario',
					unusual_location:
						'Siempre que un usuario inicie sesión desde un país diferente',
					unusual_location_and_device:
						'Cada vez que un usuario inicia sesión desde otro país con un dispositivo diferente',
				},
				notify_header: 'Notificarme',
				title: 'Notificaciones de amenazas de seguridad',
				unusual_signin: 'Inicio de sesión inusual',
			},
		},
	},
	{
		key: 'fr',
		content: {
			dashboard: {
				add_alternate_contacts: 'Ajouter des contacts secondaires',
				admin: 'Admin',
				alert_disclaimer:
					'Nous envoyons des alertes de sécurité chaque fois que nous détectons une activité potentiellement malveillante. Choisissez les activités pour lesquelles vous souhaitez que nous informions vos utilisateurs.',
				alternate_contact_description:
					"Vous pouvez configurer jusqu'à deux utilisateurs ou deux groupes de distribution à qui envoyer les alertes",
				alternate_contact_error_count:
					"vous ne pouvez ajouter qu'un total de quatre contacts secondaires",
				alternate_contact_error_dg:
					"vous ne pouvez ajouter qu'un total de deux groupes de distribution comme contacts secondaires",
				alternate_contact_error_user:
					"vous ne pouvez ajouter qu'un total de deux utilisateurs comme contacts secondaires",
				alternate_contact_field: 'Utilisateur/Groupes de distribution',
				alternate_contact_header: 'Contacts secondaires',
				clients: 'Clients',
				employees: 'Employés',
				error_message:
					"Impossible de récupérer les préférences d'alerte. Veuillez réessayer ultérieurement.",
				messages: {
					malware_upload: 'Un fichier suspect a été chargé dans un dossier',
					unusual_auth_failure:
						"Plusieurs tentatives de connexion ont échoué sur le compte d'un utilisateur",
					unusual_location: 'Un utilisateur se connecte depuis un autre pays',
					unusual_location_and_device:
						"Un utilisateur se connecte depuis une autre ville à l'aide d'un autre appareil",
				},
				save: 'Enregistrer',
				save_success: "Préférences d'alerte enregistrées avec succès",
				save_success_alternate_contact: 'Contacts secondaires enregistrés avec succès',
				save_unsuccessful: "Impossible d'enregistrer les préférences d'alerte de menace",
				save_unsuccessful_alternate_contact:
					"Impossible d'enregistrer les contacts secondaires",
				send_alert: 'Envoyer une alerte quand...',
				send_alert_to: "Envoyer des notifications sur l'activité à...",
				title: 'Paramètres des alertes de sécurité',
			},
			legacyTokenInterceptor: {
				buttonText: 'Connexion',
				message:
					'Nous vous ramènerons ici pour que vous puissiez reprendre là où vous vous étiez arrêté.',
				title:
					"Connectez-vous à nouveau pour accéder aux paramètres des notifications d'alerte de sécurité",
			},
			user_settings_dashboard: {
				alert_header: "Type d'alerte",
				description:
					"Les notifications d'atteinte à la sécurité vous sont envoyées par e-mail chaque fois qu'une activité potentiellement malveillante est détectée. À l'aide des paramètres ci-dessous, sélectionnez la menace pour laquelle vous souhaitez recevoir des notifications par courrier électronique.",
				failed_attempts: 'Multiples tentatives de connexion infructueuses',
				malware_upload: 'Chargement de logiciel malveillant',
				messages: {
					malware_upload:
						"Alerte déclenchée lorsqu'un fichier suspect a été chargé dans un dossier",
					unusual_auth_failure:
						"Alerte déclenchée lorsque plusieurs tentatives de connexion ont échoué sur le compte d'un utilisateur",
					unusual_location:
						"Chaque fois qu'un utilisateur se connecte depuis un autre pays",
					unusual_location_and_device:
						"Chaque fois qu'un utilisateur se connecte depuis un autre pays à l'aide d'un autre appareil",
				},
				notify_header: "M'avertir",
				title: "Notifications d'atteinte à la sécurité",
				unusual_signin: 'Connexion inhabituelle',
			},
		},
	},
	{
		key: 'it',
		content: {
			dashboard: {
				add_alternate_contacts: 'Aggiungere contatti alternativi',
				admin: 'Amministratore',
				alert_disclaimer:
					'Inviamo avvisi di sicurezza ogni volta che rileviamo attività potenzialmente dannose. Scegliere le attività di cui si desidera che informiamo gli utenti.',
				alternate_contact_description:
					'È possibile configurare fino a due utenti o due gruppi di distribuzione per ricevere gli avvisi',
				alternate_contact_error_count:
					'è possibile aggiungere solo un totale di quattro contatti alternativi',
				alternate_contact_error_dg:
					'è possibile aggiungere solo un totale di due gruppi di distribuzione come contatti alternativi',
				alternate_contact_error_user:
					'è possibile aggiungere solo un totale di due utenti come contatti alternativi',
				alternate_contact_field: 'Utente/Gruppi di distribuzione',
				alternate_contact_header: 'Contatti alternativi',
				clients: 'Clienti',
				employees: 'Dipendenti',
				error_message:
					'Impossibile recuperare le preferenze di avviso. Riprovare più tardi.',
				messages: {
					malware_upload: 'Viene caricato un file sospetto in una cartella',
					unusual_auth_failure:
						"Ci sono più tentativi di accesso non riusciti sull'account di un utente",
					unusual_location: 'Un utente accede da un altro paese',
					unusual_location_and_device:
						"Un utente accede da un'altra città usando un dispositivo diverso",
				},
				save: 'Salva',
				save_success: 'Preferenze di avviso salvate correttamente',
				save_success_alternate_contact: 'Contatti alternativi salvati correttamente',
				save_unsuccessful: 'Impossibile salvare le preferenze degli avvisi di minaccia',
				save_unsuccessful_alternate_contact: 'Impossibile salvare i contatti alternativi',
				send_alert: 'Invia un avviso ogni volta che...',
				send_alert_to: "Invia notifiche sull'attività a...",
				title: 'Impostazioni degli avvisi di sicurezza',
			},
			legacyTokenInterceptor: {
				buttonText: 'Accedi',
				message:
					'Ti riporteremo qui in modo che tu possa riprendere da dove eri rimasto.',
				title:
					"Effettuare nuovamente l'accesso per accedere alle impostazioni di notifica degli avvisi di sicurezza",
			},
			user_settings_dashboard: {
				alert_header: 'Tipo di avviso',
				description:
					'Le notifiche sulle minacce alla sicurezza vengono inviate per e-mail ogni volta che vengono rilevate attività potenzialmente dannose. Utilizzare le impostazioni sottostanti per selezionare le attività per cui si desidera ricevere notifiche via e-mail.',
				failed_attempts: 'Più tentativi di accesso non riusciti',
				malware_upload: 'Caricamento di malware',
				messages: {
					malware_upload:
						'Attivato quando viene caricato un file sospetto in una cartella',
					unusual_auth_failure:
						"Attivato quando ci sono più tentativi di accesso non riusciti sull'account di un utente",
					unusual_location: "Ogni volta che l'utente accede da un paese diverso",
					unusual_location_and_device:
						'Ogni volta che un utente accede da un paese diverso utilizzando un dispositivo diverso',
				},
				notify_header: 'Avvisami',
				title: 'Notifiche sulle minacce alla sicurezza',
				unusual_signin: 'Accesso insolito',
			},
		},
	},
	{
		key: 'ja',
		content: {
			dashboard: {
				add_alternate_contacts: '別の連絡先を追加する',
				admin: '管理者',
				alert_disclaimer:
					'悪意の可能性があるアクティビティを検出すると、セキュリティ警告が送信されます。ユーザーに通知する必要があるアクティビティを選択してください。',
				alternate_contact_description:
					'アラートを受信する最大 2 人のユーザーまたは 2 個の配布グループを構成できます。',
				alternate_contact_error_count: '別の連絡先を合計 4 個のみ追加できます',
				alternate_contact_error_dg:
					'合計2 個の配布グループのみを別の連絡先として追加することができます',
				alternate_contact_error_user:
					'合計 2 人のユーザーのみを別の連絡先として追加できます',
				alternate_contact_field: 'ユーザー/配布グループ',
				alternate_contact_header: '別の連絡先',
				clients: 'クライアント',
				employees: '従業員',
				error_message: 'アラート設定を取得できませんでした。後でもう一度試してください。',
				messages: {
					malware_upload: '不審なファイルがフォルダーにアップロードされました',
					unusual_auth_failure:
						'ユーザーのアカウントへのサインインの試みが複数回失敗しました。',
					unusual_location: 'ユーザーは別の国からサインインしています',
					unusual_location_and_device:
						'ユーザーは別のデバイスを使用して別の都市からサインインしています',
				},
				save: '保存',
				save_success: 'アラート設定が正常に保存されました',
				save_success_alternate_contact: '別の連絡先が保存されました',
				save_unsuccessful: '脅威のアラート設定を保存できません',
				save_unsuccessful_alternate_contact: '別の連絡先を保存できません',
				send_alert: '常にアラートを送信する...',
				send_alert_to: 'アクティビティに関する通知を次の宛先に送信する...',
				title: 'セキュリティ アラート設定',
			},
			legacyTokenInterceptor: {
				buttonText: 'サインイン',
				message: 'ここに戻りますので、中断したところから再開できます。',
				title: '再度サインインして、セキュリティ アラート通知設定にアクセスします',
			},
			user_settings_dashboard: {
				alert_header: 'アラートの種類',
				description:
					'セキュリティ脅威の通知は、潜在的に悪意のあるアクティビティが検出されるたびにメールで送信されます。 以下の設定を使用して、メール通知を受信するアクティビティを選択します。',
				failed_attempts: 'サインインの試みが複数回失敗しました',
				malware_upload: 'マルウェアのアップロード',
				messages: {
					malware_upload:
						'不審なファイルがフォルダーにアップロードされたときにトリガーされました',
					unusual_auth_failure:
						'ユーザーのアカウントへのサインインの試みが複数回失敗したときにトリガーされました',
					unusual_location: 'ユーザーが別の国からサインインしたとき',
					unusual_location_and_device:
						'ユーザーが別のデバイスを使用して別の都市からサインインしたとき',
				},
				notify_header: '通知する',
				title: 'セキュリティ脅威の通知',
				unusual_signin: '異常なサインイン',
			},
		},
	},
	{
		key: 'ko',
		content: {
			dashboard: {
				add_alternate_contacts: 'Add Alternate Contacts',
				admin: '관리자',
				alert_disclaimer:
					'악의적일 수 있는 활동이 감지될 때마다 보안 경고가 전송됩니다. 사용자에게 알리고 싶은 활동을 선택하세요.',
				alternate_contact_description:
					'You can configure up to two users or distribution groups to recieve the alerts',
				alternate_contact_error_count:
					'you can only add a total of four alternate contacts',
				alternate_contact_error_dg:
					'you can only add a total of two Distirbution Groups as alternate contacts',
				alternate_contact_error_user:
					'you can only add a total of two users as alternate contacts',
				alternate_contact_field: 'User / Distirbution Groups',
				alternate_contact_header: 'Alternate Contacts',
				clients: '클라이언트',
				employees: '직원',
				error_message: '알림 환경설정을 가져오지 못했습니다. 나중에 다시 시도하십시오.',
				messages: {
					malware_upload: '폴더에 의심스러운 파일이 업로드되었습니다',
					unusual_auth_failure: '사용자 계정에 대한 로그인 시도가 여러 번 실패했습니다',
					unusual_location: '사용자가 다른 국가에서 로그인함',
					unusual_location_and_device: '사용자가 다른 기기로 다른 도시에서 로그인함',
				},
				save: '저장',
				save_success: '알림 환경설정 저장됨',
				save_success_alternate_contact: 'Alternate contacts saved successfully',
				save_unsuccessful: '위협 알림 환경설정을 저장할 수 없음',
				save_unsuccessful_alternate_contact: 'Unable to save alternate contacts',
				send_alert: '다음 경우에 항상 알림 보내기...',
				send_alert_to: '다음으로 활동에 관한 알림 보내기...',
				title: '보안 알림 설정',
			},
			legacyTokenInterceptor: {
				buttonText: 'Sign in',
				message: "We'll bring you right back here so you can pick up where you left off.",
				title: 'Sign in again to access the Security Alert Notification Settings',
			},
			user_settings_dashboard: {
				alert_header: 'Alert type',
				description:
					'Notifications for security threats are sent to you over email every time potentially malicious activity is detected. Use the below settings to select what activity you would like to receive email notifications for.',
				failed_attempts: 'Multiple failed sign-in attempts',
				malware_upload: 'Malware upload',
				messages: {
					malware_upload: 'Triggered when a suspicious file is uploaded to a folder',
					unusual_auth_failure:
						"Triggered when there are multiple failed sign-in attempts on a user's account",
					unusual_location: 'Every time user signs in from a different country',
					unusual_location_and_device:
						'Every time user signs in from a different country using a different device',
				},
				notify_header: 'Notify me',
				title: 'Security Threat Notifications',
				unusual_signin: 'Unusual sign-in',
			},
		},
	},
	{
		key: 'nl',
		content: {
			dashboard: {
				add_alternate_contacts: 'Alternatieve contacten toevoegen',
				admin: 'Beheer',
				alert_disclaimer:
					'We sturen beveiligingswaarschuwingen wanneer we mogelijk kwaadaardige activiteiten detecteren. Kies over welke activiteiten wij uw gebruikers op de hoogte moeten stellen.',
				alternate_contact_description:
					'U kunt maximaal twee gebruikers of twee distributiegroepen configureren om de meldingen te ontvangen',
				alternate_contact_error_count:
					'u kunt in totaal slechts vier alternatieve contacten toevoegen',
				alternate_contact_error_dg:
					'u kunt in totaal slechts twee distributiegroepen als alternatieve contactpersonen toevoegen',
				alternate_contact_error_user:
					'u kunt in totaal slechts twee gebruikers als alternatieve contacten toevoegen',
				alternate_contact_field: 'Gebruiker / Distributiegroepen',
				alternate_contact_header: 'Alternatieve contacten',
				clients: 'Klanten',
				employees: 'Medewerkers',
				error_message:
					'Kan de waarschuwingsvoorkeuren niet ophalen. Probeer het later opnieuw.',
				messages: {
					malware_upload: 'Er wordt een verdacht bestand naar een map geüpload',
					unusual_auth_failure:
						'Er worden meerdere mislukte aanmeldpogingen gedaan op een gebruikersaccount',
					unusual_location: 'Een gebruiker meldt zich aan vanuit een ander land',
					unusual_location_and_device:
						'Een gebruiker meldt zich aan vanuit een andere stad met een ander apparaat',
				},
				save: 'Opslaan',
				save_success: 'Waarschuwingsvoorkeuren succesvol opgeslagen',
				save_success_alternate_contact: 'Alternatieve contacten opgeslagen',
				save_unsuccessful: 'Kan voorkeuren voor beveiligingswaarschuwingen niet opslaan',
				save_unsuccessful_alternate_contact: 'Kan alternatieve contacten niet opslaan',
				send_alert: 'Stuur een melding wanneer...',
				send_alert_to: 'Meldingen over activiteiten sturen naar...',
				title: 'Instellingen voor beveiligingswaarschuwingen',
			},
			legacyTokenInterceptor: {
				buttonText: 'Aanmelden',
				message:
					'U wordt zo meteen naar hier teruggeleid, zodat u verder kunt gaan waar u was gebleven.',
				title:
					'Meld u opnieuw aan om toegang te krijgen tot de Instellingen voor Beveiligingswaarschuwingen',
			},
			user_settings_dashboard: {
				alert_header: 'Meldingstype',
				description:
					'Meldingen voor beveiligingsbedreigingen worden u via e-mail toegestuurd telkens wanneer mogelijk schadelijke activiteit wordt gedetecteerd. Gebruik onderstaande instellingen om te selecteren voor welke activiteit u e-mailmeldingen wilt ontvangen.',
				failed_attempts: 'Meerdere mislukte aanmeldpogingen',
				malware_upload: 'Malware-upload',
				messages: {
					malware_upload:
						'Getriggerd wanneer een verdacht bestand wordt geüpload naar een map',
					unusual_auth_failure:
						'Getriggerd wanneer er meerdere mislukte aanmeldpogingen op een gebruikersaccount worden gedaan',
					unusual_location:
						'Elke keer dat de gebruiker zich aanmeldt vanuit een ander land',
					unusual_location_and_device:
						'Elke keer dat de gebruiker zich aanmeldt vanuit een ander land via een ander apparaat',
				},
				notify_header: 'Stuur me een melding',
				title: 'Meldingen van beveiligingsbedreigingen',
				unusual_signin: 'Ongewone aanmelding',
			},
		},
	},
	{
		key: 'pt-BR',
		content: {
			dashboard: {
				add_alternate_contacts: 'Adicionar contatos alternativos',
				admin: 'Admin',
				alert_disclaimer:
					'Enviamos alertas de segurança sempre que detectamos atividades potencialmente maliciosas. Escolha as atividades sobre as quais você prefere que notifiquemos seus usuários.',
				alternate_contact_description:
					'Você pode configurar até dois usuários ou dois grupos de distribuição para receber os alertas',
				alternate_contact_error_count:
					'você só pode adicionar um total de quatro contatos alternativos',
				alternate_contact_error_dg:
					'você só pode adicionar um total de dois grupos de distribuição como contatos alternativos',
				alternate_contact_error_user:
					'você só pode adicionar um total de dois usuários como contatos alternativos',
				alternate_contact_field: 'Usuário/Grupos de distribuição',
				alternate_contact_header: 'Contatos alternativos',
				clients: 'Clientes',
				employees: 'Funcionários',
				error_message:
					'Falha ao obter as preferências de alerta. Tente novamente mais tarde.',
				messages: {
					malware_upload: 'Um arquivo suspeito é carregado em uma pasta',
					unusual_auth_failure:
						'Existem várias tentativas de login com falha na conta de um usuário',
					unusual_location: 'Um usuário faz login de outro país',
					unusual_location_and_device:
						'Um usuário faz login de outra cidade usando outro dispositivo',
				},
				save: 'Salvar',
				save_success: 'Preferências de alerta salvas com sucesso',
				save_success_alternate_contact: 'Contatos alternativos salvos com sucesso',
				save_unsuccessful: 'Não é possível salvar as preferências de alerta de ameaças',
				save_unsuccessful_alternate_contact:
					'Não é possível salvar contatos alternativos',
				send_alert: 'Enviar um alerta sempre que...',
				send_alert_to: 'Envie notificações sobre atividades para...',
				title: 'Configurações de alerta de segurança',
			},
			legacyTokenInterceptor: {
				buttonText: 'Fazer login',
				message:
					'Nós o traremos de volta aqui para que você possa continuar de onde parou.',
				title:
					'Faça logon novamente para acessar as configurações de notificação de alerta de segurança',
			},
			user_settings_dashboard: {
				alert_header: 'Tipo de alerta',
				description:
					'Notificações de ameaças à segurança são enviadas por email sempre que uma atividade potencialmente maliciosa é detectada. Use as configurações abaixo para selecionar para qual atividade você gostaria de receber emails de notificação.',
				failed_attempts: 'Várias tentativas malsucedidas de logon',
				malware_upload: 'Upload de malware',
				messages: {
					malware_upload:
						'Acionado quando um arquivo suspeito é carregado para uma pasta',
					unusual_auth_failure:
						'Acionado quando há várias tentativas de logon com falha na conta de um usuário',
					unusual_location: 'Toda vez que o usuário faz logon de um país diferente',
					unusual_location_and_device:
						'Toda vez que o usuário faz logon de um país diferente usando um dispositivo diferente',
				},
				notify_header: 'Notificar-me',
				title: 'Notificações de ameaças à segurança',
				unusual_signin: 'Logon incomum',
			},
		},
	},
	{
		key: 'ru',
		content: {
			dashboard: {
				add_alternate_contacts: 'Add Alternate Contacts',
				admin: 'Администратор',
				alert_disclaimer:
					'Мы отправляем оповещения системы безопасности при обнаружении потенциально вредоносных действий. Выберите, о каких действиях вы хотите, чтобы мы уведомляли ваших пользователей.',
				alternate_contact_description:
					'You can configure up to two users or distribution groups to recieve the alerts',
				alternate_contact_error_count:
					'you can only add a total of four alternate contacts',
				alternate_contact_error_dg:
					'you can only add a total of two Distirbution Groups as alternate contacts',
				alternate_contact_error_user:
					'you can only add a total of two users as alternate contacts',
				alternate_contact_field: 'User / Distirbution Groups',
				alternate_contact_header: 'Alternate Contacts',
				clients: 'Клиенты',
				employees: 'Сотрудники',
				error_message:
					'Не удалось получить настройки оповещений. Повторите попытку позже.',
				messages: {
					malware_upload: 'Подозрительный файл загружен в папку',
					unusual_auth_failure:
						'Предпринимается несколько неудачных попыток войти в учетную запись пользователя',
					unusual_location: 'Пользователь входит в систему из другой страны',
					unusual_location_and_device:
						'Пользователь входит в систему из другого города, используя другое устройство',
				},
				save: 'Сохранить',
				save_success: 'Настройки оповещений успешно сохранены',
				save_success_alternate_contact: 'Alternate contacts saved successfully',
				save_unsuccessful: 'Не удается сохранить настройки оповещений об угрозах',
				save_unsuccessful_alternate_contact: 'Unable to save alternate contacts',
				send_alert: 'Отправлять оповещения, когда...',
				send_alert_to: 'Отправлять уведомления о действиях...',
				title: 'Параметры оповещений системы безопасности',
			},
			legacyTokenInterceptor: {
				buttonText: 'Sign in',
				message: "We'll bring you right back here so you can pick up where you left off.",
				title: 'Sign in again to access the Security Alert Notification Settings',
			},
			user_settings_dashboard: {
				alert_header: 'Alert type',
				description:
					'Notifications for security threats are sent to you over email every time potentially malicious activity is detected. Use the below settings to select what activity you would like to receive email notifications for.',
				failed_attempts: 'Multiple failed sign-in attempts',
				malware_upload: 'Malware upload',
				messages: {
					malware_upload: 'Triggered when a suspicious file is uploaded to a folder',
					unusual_auth_failure:
						"Triggered when there are multiple failed sign-in attempts on a user's account",
					unusual_location: 'Every time user signs in from a different country',
					unusual_location_and_device:
						'Every time user signs in from a different country using a different device',
				},
				notify_header: 'Notify me',
				title: 'Security Threat Notifications',
				unusual_signin: 'Unusual sign-in',
			},
		},
	},
	{
		key: 'zh-CN',
		content: {
			dashboard: {
				add_alternate_contacts: 'Add Alternate Contacts',
				admin: '管理',
				alert_disclaimer:
					'每当我们检测到潜在的恶意活动时都会发送安全警报。请选择您希望我们通知您的用户哪些活动。',
				alternate_contact_description:
					'You can configure up to two users or distribution groups to recieve the alerts',
				alternate_contact_error_count:
					'you can only add a total of four alternate contacts',
				alternate_contact_error_dg:
					'you can only add a total of two Distirbution Groups as alternate contacts',
				alternate_contact_error_user:
					'you can only add a total of two users as alternate contacts',
				alternate_contact_field: 'User / Distirbution Groups',
				alternate_contact_header: 'Alternate Contacts',
				clients: '客户',
				employees: '员工',
				error_message: '无法获取警报首选项。请稍后再试。',
				messages: {
					malware_upload: '可疑文件已上载到文件夹',
					unusual_auth_failure: '用户的帐户多次尝试登录均失败',
					unusual_location: '用户从不同的国家/地区登录',
					unusual_location_and_device: '用户使用不同的设备从不同的城市登录',
				},
				save: '保存',
				save_success: '警报首选项已成功保存',
				save_success_alternate_contact: 'Alternate contacts saved successfully',
				save_unsuccessful: '无法保存威胁警报首选项',
				save_unsuccessful_alternate_contact: 'Unable to save alternate contacts',
				send_alert: '以下情况下发送警报...',
				send_alert_to: '将有关活动的通知发送至...',
				title: '安全警报设置',
			},
			legacyTokenInterceptor: {
				buttonText: 'Sign in',
				message: "We'll bring you right back here so you can pick up where you left off.",
				title: 'Sign in again to access the Security Alert Notification Settings',
			},
			user_settings_dashboard: {
				alert_header: 'Alert type',
				description:
					'Notifications for security threats are sent to you over email every time potentially malicious activity is detected. Use the below settings to select what activity you would like to receive email notifications for.',
				failed_attempts: 'Multiple failed sign-in attempts',
				malware_upload: 'Malware upload',
				messages: {
					malware_upload: 'Triggered when a suspicious file is uploaded to a folder',
					unusual_auth_failure:
						"Triggered when there are multiple failed sign-in attempts on a user's account",
					unusual_location: 'Every time user signs in from a different country',
					unusual_location_and_device:
						'Every time user signs in from a different country using a different device',
				},
				notify_header: 'Notify me',
				title: 'Security Threat Notifications',
				unusual_signin: 'Unusual sign-in',
			},
		},
	},
];
