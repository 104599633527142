import React, { useEffect, useState } from 'react';
import { Contact as ContactItem, ContactMultiSelect } from '@citrite/citrix-files-ui';
import {
	Button,
	CircleOutlinedXIcon,
	notifyError,
	notifySuccess,
	SettingsField,
} from '@citrite/citrix-ui';
import { Contact, ContactListType, GroupsEntity, UsersEntity } from '@citrite/sf-api';
import { Description } from '../SecurityAlertSettings/styles';
import { CCEntities } from '../SecurityAlertSettings/types';
import { t } from '../utils';
import { ThreatAlertManagementHttp } from '../utils/ThreatAlertManagementService/ThreatAlertManagementHttp';
import {
	StyledContactInfo,
	StyledErrorMessage,
	StyledInfo,
	StyledSaveButtonDiv,
} from './styles';

export interface AlternateContactSettingsProps {
	CCEntities?: CCEntities;
}

export const AlternateContactSettings = (props: AlternateContactSettingsProps) => {
	const [contactList, setContactList] = useState<Array<Contact>>([]);
	const [saving, setSaving] = useState<boolean>(false);

	const onSelectContact = (...contact: Array<Contact>) => {
		const tempList: Array<Contact> = [];

		contact.forEach(newContact => {
			if (contactList.find(c => c.Id === newContact.Id)) {
				return;
			}

			tempList.push(newContact);
		});

		pushContacts(tempList);
	};

	const onRemoveContact = (contact: Contact) => {
		setContactList(contactList.filter(c => c.Id !== contact.Id));
		saveButtonStatusCheck();
	};

	const save = () => {
		setSaving(true);

		const userIds = contactList.filter(c => !c.Id?.startsWith('g')).map(c => c.Id || '');
		const groupIds = contactList.filter(c => c.Id?.startsWith('g')).map(c => c.Id || '');

		if (userIds.length > 2 || groupIds.length > 2) {
			setSaving(false);
			return;
		}

		const ccEntities: CCEntities = {
			userIds: userIds,
			distributionGroupIds: groupIds,
		};

		ThreatAlertManagementHttp.saveCCEntities(ccEntities)
			.then(response => {
				if (response) {
					notifySuccess(t('alert-management:dashboard.save_success_alternate_contact'));
				}
			})
			.catch(() => {
				notifyError(t('alert-management:dashboard.save_unsuccessful_alternate_contact'));
			})
			.finally(() => {
				setSaving(false);
			});
	};

	const saveButtonStatusCheck = () => {
		if (
			contactList.length > 4 ||
			contactList.filter(c => c.Id?.startsWith('g')).length > 2 ||
			contactList.filter(c => !c.Id?.startsWith('g')).length > 2
		) {
			return true;
		}

		return false;
	};

	const getSelectionErrorMessage = () => {
		if (!saveButtonStatusCheck()) {
			return '';
		} else if (contactList.length > 4) {
			return '* ' + t('alert-management:dashboard.alternate_contact_error_count');
		} else if (contactList.filter(c => c.Id?.startsWith('g')).length > 2) {
			return '* ' + t('alert-management:dashboard.alternate_contact_error_dg');
		} else if (contactList.filter(c => !c.Id?.startsWith('g')).length > 2) {
			return '* ' + t('alert-management:dashboard.alternate_contact_error_user');
		}
	};

	const pushContacts = (contacts: Array<Contact>) => {
		setContactList(prevContacts => [...prevContacts, ...contacts]);
		saveButtonStatusCheck();
	};

	useEffect(
		() => {
			props.CCEntities?.userIds?.forEach(userId => {
				UsersEntity.get(userId)
					.execute()
					.then(user => {
						const contact: Contact = {
							Id: user.Id,
							FirstName: user.FirstName,
							LastName: user.LastName,
							Name: `${user.FirstName} ${user.LastName}`,
							Email: user.Email,
							Company: user.Company,
						};

						pushContacts([contact]);
					});
			});

			props.CCEntities?.distributionGroupIds?.forEach(groupId => {
				GroupsEntity.get(groupId)
					.execute()
					.then(group => {
						const contact: Contact = {
							Id: group.Id,
							Name: group.Name,
							Count: group.Contacts?.length,
							Email: group.Email,
							Company: '',
						};

						pushContacts([contact]);
					});
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<SettingsField>
			<h2>{t('alert-management:dashboard.add_alternate_contacts')}</h2>
			<Description>
				{t('alert-management:dashboard.alternate_contact_description')}
			</Description>
			<StyledInfo>{t('alert-management:dashboard.alternate_contact_field')}</StyledInfo>
			<ContactMultiSelect
				contactType={ContactListType.AllUsersWithGroups}
				onSelect={onSelectContact}
				canShowAddressBook
			/>
			<StyledInfo>{t('alert-management:dashboard.alternate_contact_header')}</StyledInfo>
			{contactList.map(contact => {
				return (
					<div key={contact.Id}>
						<StyledContactInfo>
							<ContactItem contact={contact} />
							<Button.IconOnly
								title="Remove"
								data-testid="remove-button"
								size={20}
								icon={CircleOutlinedXIcon}
								onClick={() => {
									onRemoveContact(contact);
								}}
							/>
						</StyledContactInfo>
					</div>
				);
			})}
			{<StyledErrorMessage>{getSelectionErrorMessage()}</StyledErrorMessage>}
			<StyledSaveButtonDiv>
				<Button.Primary
					data-pendo-id="SaveAlternateContactThreatAlertPilet"
					data-testid="save-button"
					onClick={() => save()}
					active={saving}
					disabled={saveButtonStatusCheck()}
				>
					{t('alert-management:dashboard.save')}
				</Button.Primary>
			</StyledSaveButtonDiv>
		</SettingsField>
	);
};
