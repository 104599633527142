import * as React from 'react';
import { useState } from 'react';
import {
	Button,
	notifyError,
	notifySuccess,
	PageTitle,
	SettingsField,
} from '@citrite/citrix-ui';
import { t } from '../utils';
import { ThreatAlertManagementHttp } from '../utils/ThreatAlertManagementService/ThreatAlertManagementHttp';
import {
	AlignRight,
	BoldText,
	BoldTextHeading,
	Description,
	MessageHeading,
	PermissionsColumn,
	StyledRow,
	TableContent,
	TableHeader,
} from './styles';
import { ThreatAlert } from './ThreatAlert';
import { AccountAlertPreferences, UserRole } from './types';

export interface SecurityAlertSettingsProps {
	threatAlertPreferences: AccountAlertPreferences[];
}

export var threatsMessage = (alert: any) => {
	switch (alert) {
		case 'UnusualLocation':
			return t('alert-management:dashboard.messages.unusual_location');
		case 'UnusualLocationAndDevice':
			return t('alert-management:dashboard.messages.unusual_location_and_device');
		case 'UnusualAuthFailure':
			return t('alert-management:dashboard.messages.unusual_auth_failure');
		case 'MalwareUpload':
			return t('alert-management:dashboard.messages.malware_upload');
		default:
			return '';
	}
};

export const SecurityAlertSettings = (props: SecurityAlertSettingsProps) => {
	const [threatAlertPreferences, setThreatAlertPreferences] = useState<
		Array<AccountAlertPreferences>
	>(props.threatAlertPreferences);
	const [saving, setSaving] = useState<boolean>(false);

	const changepref = (alert: any, role: any, status: boolean) => {
		const preference = threatAlertPreferences;
		const threatPreferences = preference.find(pref => pref.threat === alert);
		const userPreferences = threatPreferences?.threatAlertPreference?.find(
			pref => pref.userType === role
		);

		if (userPreferences) {
			userPreferences.isEnabled = status;
		}

		setThreatAlertPreferences(preference);
	};

	const save = async () => {
		try {
			setSaving(true);
			var preferencesSaved = await ThreatAlertManagementHttp.saveAlertPreferences(
				threatAlertPreferences
			);
			setSaving(false);
			if (preferencesSaved) {
				notifySuccess(t('alert-management:dashboard.save_success'));
			} else {
				throw 'operation failed';
			}
		} catch (err: any) {
			notifyError(t('alert-management:dashboard.save_unsuccessful'));
		}
	};

	return (
		<div>
			<PageTitle>
				<BoldText data-pendo-id="SecurityAlertSettingsThreatAlertPilet">
					{t('alert-management:dashboard.title')}
				</BoldText>
			</PageTitle>
			<SettingsField>
				<Description>{t('alert-management:dashboard.alert_disclaimer')}</Description>
				<StyledRow />
				<AlignRight>
					<BoldTextHeading>
						{t('alert-management:dashboard.send_alert_to')}
					</BoldTextHeading>
				</AlignRight>
				<TableHeader>
					<MessageHeading>{t('alert-management:dashboard.send_alert')}</MessageHeading>
					<PermissionsColumn>{t('alert-management:dashboard.admin')}</PermissionsColumn>
					<PermissionsColumn>
						{t('alert-management:dashboard.employees')}
					</PermissionsColumn>
					<PermissionsColumn>{t('alert-management:dashboard.clients')}</PermissionsColumn>
				</TableHeader>
				<TableContent>
					{threatAlertPreferences.map((threat, index) => (
						<ThreatAlert
							key={index}
							alertMessage={threatsMessage(threat.threat)}
							isAdminAlertChecked={
								threat.threatAlertPreference?.find(
									pref => pref.userType === UserRole.MasterAdmin
								)?.isEnabled ?? true
							}
							isEmployeeAlertChecked={
								threat.threatAlertPreference?.find(
									pref => pref.userType === UserRole.Employee
								)?.isEnabled ?? true
							}
							isClientAlertChecked={
								threat.threatAlertPreference?.find(
									pref => pref.userType === UserRole.Client
								)?.isEnabled ?? true
							}
							threatName={threat.threat}
							onChange={changepref}
						/>
					))}
				</TableContent>
				<StyledRow />
				<AlignRight>
					<Button.Primary
						data-pendo-id="SaveThreatAlertPilet"
						onClick={() => save()}
						active={saving}
					>
						{t('alert-management:dashboard.save')}
					</Button.Primary>
				</AlignRight>
			</SettingsField>
		</div>
	);
};
